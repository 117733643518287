@use 'styles/base/mixins';
@use 'styles/base/variables';

.tile {
  position: relative;
  background: variables.$gb_grey_055;
  margin-bottom: 1.875rem;
  padding-bottom: 3.75rem;

  @include mixins.screen-md {
    padding-bottom: 3.75rem;
  }
}
:global(.c-leadmodule) {
  :global(.large-6) {
    :local(.content) {
      margin: 1.25rem 1.25rem;

      @include mixins.screen-md {
        margin: 2.375rem 6.25rem;
      }

      :global(.h4) {
        margin-top: 1.375rem;
        margin-bottom: 1.375rem;
        @include mixins.screen-md {
          margin-top: 0;
        }
      }

      .icon {
        @include mixins.screen-md {
          position: absolute;
          left: 1.25rem;
          top: 1.125rem;
        }
      }
    }
  }
  :global(.large-4) {
    :local(.content) {
      margin: 1.25rem 1.25rem;

      :global(.h4) {
        margin-top: 1.375rem;
        margin-bottom: 1.375rem;
      }

      :local(.formButtonWrapper),
      :global(.c-toolembed) {
        @include mixins.screen-md {
          left: 1.125rem;
          right: 1.125rem;
          bottom: 1.125rem;
        }
      }
    }
  }
  .content {
    .icon {
      font-size: 3.75rem;
      margin-bottom: 1.25rem;
      line-height: 0.75rem;
      color: var(--gb-brand-primary-light);
    }

    :global(.title--block) {
      max-height: 5rem;
      overflow: hidden;

      @include mixins.screen-md {
        max-height: 3rem;
      }
    }

    :global(.h4) {
      font-weight: bold;

      @include mixins.screen-md {
        margin-top: 0;
      }
    }

    .paragraph {
      display: block;

      @include mixins.screen-md {
        max-height: 7.5rem;
        overflow: hidden;
      }
    }

    :local(.formButtonWrapper),
    :global(.c-toolembed) {
      position: absolute;
      left: 1.125rem;
      right: 1.125rem;
      bottom: 1.125rem;
      width: inherit;

      @include mixins.screen-md {
        bottom: 2.375rem;
        left: 6.25rem;
        right: 6.25rem;
      }
    }
  }
}

.leadForm {
  text-align: left;

  :global(form) {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
